import React from 'react'
import SEO from 'src/components/seo'
import Layout from 'src/components/layout/layout'
import { usePrivateRoute } from 'src/utils/auth'
import { USTARankings } from 'src/components/usta-rankings/usta-rankings'
import TeamRankings from 'src/components/team-rankings/team-rankings'
import RankingGroups from 'src/components/rankings/rankings-home'
import { useV2Rankings } from 'src/utils/useV2Rankings'

const IndexPage: React.FC = () => {
  usePrivateRoute()
  const v2Rankings = useV2Rankings()
  return (
    <Layout>
      <SEO title="Rankings" />
      {process.env.GATSBY_CLIENT === 'ITA' && <TeamRankings />}
      {process.env.GATSBY_CLIENT === 'USTA' && v2Rankings && <RankingGroups />}
      {process.env.GATSBY_CLIENT === 'USTA' && !v2Rankings && <USTARankings />}
    </Layout>
  )
}

export default IndexPage

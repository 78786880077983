import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { Grid } from '@material-ui/core'
import { Body, BodyRegularBold } from '../../typography/typography'
import { AgeRestrictionEnum, ListTypeEnum, PlayerTypeEnum, RankListGenderEnum } from 'src/graphql-types/globalRankingTypes'
import { DivisionTypesEnum, useCircuitOptions } from 'src/utils/helper/rankings'
import useRankListOptions from '../utils/useRankListOptions'
import { getFirstOptionValue } from 'src/utils/helper/membership'
import Dropdown from '../../dropdown/dropdown'
import DatePicker from '../../date-picker/date-picker'
import TextInput from '../../input/input'
import { getNTRPLevelOptions } from '../utils/getNTRPLevelOptions'
import { CustomGrid } from '../../custom-grid/custom-grid'
import Button from '../../button/button'
import FormErrorMessage from '../../form-error-message/form-error-message'
import { getOneDayBefore, getOneYearAgo } from 'src/utils/helper/dates'

interface NewRankingRunDialogProps {
  onCancel: () => void
  onSubmit: (formData: any) => void
  loading: boolean
  error?: string
}

const NewRankingRunDialog: React.FC<NewRankingRunDialogProps> = ({ 
  onCancel, 
  onSubmit, 
  loading, 
  error 
}) => {
  const { t } = useTranslation()
  const playerTypeOptions = useCircuitOptions()
  const [playerType, setPlayerType] = useState<PlayerTypeEnum>(PlayerTypeEnum.ADULT)
  const [divisionType, setDivisionType] = useState<DivisionTypesEnum>(DivisionTypesEnum.AGE)
  const [ageGroup, setAgeGroup] = useState<AgeRestrictionEnum>(AgeRestrictionEnum.y12)
  const [gender, setGender] = useState<RankListGenderEnum>(RankListGenderEnum.M)
  const [listType, setListType] = useState<ListTypeEnum>(ListTypeEnum.STANDING)

  const {
    divisionTypeOptions,
    genderOptions,
    formatOptions,
    ageGroupOptions,
    typeOptions,
    subDivisionOptions,
    NTRPLevelOptions = [],
    sectionOptions = []
  } = useRankListOptions({ circuit: playerType, divisionType }) as any

  const [dates, setDates] = useState<Record<'startDate' | 'endDate' | 'runDate', Date>>({
    startDate: new Date(),
    endDate: new Date(),
    runDate: new Date()
  })

  // By default, set the start and end date to construct a list across the last 12 months 
  const { startDate, endDate } = useMemo(() => {
    const today = new Date()
    return { startDate: getOneYearAgo(today), endDate: getOneDayBefore(today) }
  }, [])

  const applicableLevels = useMemo(() => 
    getNTRPLevelOptions(ageGroup), [ageGroup])

  const handleCreateNewRankList = useCallback((formData) => {
    onSubmit(formData)
  }, [onSubmit])

  const initialValues = useMemo(
    () => ({
      startDate,
      endDate,
      runDate: dates?.runDate ?? new Date(),
      playerType,
      listType: getFirstOptionValue(typeOptions),
      gender: getFirstOptionValue(genderOptions),
      ageRestriction: getFirstOptionValue(ageGroupOptions),
      matchFormat: getFirstOptionValue(formatOptions),
      subDivision: getFirstOptionValue(subDivisionOptions),
      section: getFirstOptionValue(sectionOptions),
      divisionType: getFirstOptionValue(divisionTypeOptions),
      playerLevel: NTRPLevelOptions?.length ? getFirstOptionValue(NTRPLevelOptions) : '',
      itemLimit: ''
    }),
    [
      NTRPLevelOptions, 
      ageGroupOptions, 
      dates?.runDate,
      endDate, 
      formatOptions, 
      genderOptions,
      divisionTypeOptions,
      playerType, 
      sectionOptions, 
      startDate, 
      subDivisionOptions, 
      typeOptions
    ]
  )

  const { handleSubmit, control, setValue, watch } = useForm({ defaultValues: initialValues })

  const playerTypeEvent = watch('playerType')
  const divisionTypeEvent = watch('divisionType')

  // Watch for changes in 'playerType' and 'divisionType' and update other fields accordingly
  useEffect(() => {
    setValue('listType', getFirstOptionValue(typeOptions))
    setValue('gender', getFirstOptionValue(genderOptions))
    setValue('ageRestriction', getFirstOptionValue(ageGroupOptions))
    setValue('matchFormat', getFirstOptionValue(formatOptions))
    setValue('subDivision', getFirstOptionValue(subDivisionOptions))
    setValue('section', getFirstOptionValue(sectionOptions))
    setValue('divisionType', getFirstOptionValue(divisionTypeOptions))
    setValue('playerLevel', NTRPLevelOptions?.length ? getFirstOptionValue(NTRPLevelOptions) : '')
    setValue('itemLimit', '')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerTypeEvent]) // we only care about the events we're watching

  useEffect(() => {
    setValue('listType', getFirstOptionValue(typeOptions))
    setValue('gender', getFirstOptionValue(genderOptions))
    setValue('ageRestriction', getFirstOptionValue(ageGroupOptions))
    setValue('matchFormat', getFirstOptionValue(formatOptions))
    setValue('subDivision', getFirstOptionValue(subDivisionOptions))
    setValue('section', getFirstOptionValue(sectionOptions))
    setValue('playerLevel', NTRPLevelOptions?.length ? getFirstOptionValue(NTRPLevelOptions) : '')
    setValue('itemLimit', '')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divisionTypeEvent]) // we only care about two events we're watching

  const handleFieldChange = useCallback((field, value) => {
    if (field.name === 'playerType') {
      // reset list type when player category changes
      setPlayerType(value.value || value)
      setListType(ListTypeEnum.STANDING)
    }
    if (field.name === 'listType') {
      setListType(value.value)
    }
    if (field.name === 'ageRestriction') {
      setAgeGroup(value.value)
    }
    if (field.name === 'gender') {
      setGender(value.value)
    }
    if (field.name === 'divisionType') {
      setDivisionType(value.value)
    }
    if (['startDate', 'endDate', 'runDate'].includes(field.name)) {
      setDates({ ...dates, startDate: value.value })
    }
    
    return field.onChange(value.value || value)
  },[dates])

  const hasFormatField = useMemo(() => {
    return !(playerType === PlayerTypeEnum.JUNIOR &&
      (
        listType !== ListTypeEnum.SEEDING && 
        listType !== ListTypeEnum.YEAR_END
      ))
  }, [playerType, listType])

  const hasSubDivisionField = useMemo(() => {
    return playerType === PlayerTypeEnum.WHEELCHAIR &&
      gender === RankListGenderEnum.C &&
      ageGroup === AgeRestrictionEnum.y18
  },[ageGroup, gender, playerType])

  return (
    <form onSubmit={handleSubmit(handleCreateNewRankList)} data-testid='new-ranking-run-form'>
      <Grid container direction='column'>
        <Body size="md">{t('new ranking list explanation')}</Body>
        <BodyRegularBold spacing={{ margins: { xxs: 'bottom', md: 'top' } }}>
          {t('player category')}
        </BodyRegularBold>
        <Controller
          name='playerType'
          control={control}
          render={({ field }) => (
            <Dropdown
              data-testid='playerType-dropdown'
              onSelect={(value) => handleFieldChange(field, value)}
              options={playerTypeOptions}
              selected={playerType}
              fluid
            />
          )}
        />
        {playerType !== PlayerTypeEnum.JUNIOR && (
          <>
            <BodyRegularBold spacing={{ margins: { xxs: 'bottom', md: 'top' } }}>
              {t('division type')}
            </BodyRegularBold>
            <Controller
              name='divisionType'
              control={control}
              render={({ field }) => (
                <Dropdown
                  data-testid='divisionType-dropdown'
                  onSelect={(value) => handleFieldChange(field, value)}
                  options={divisionTypeOptions}
                  selected={divisionType}
                  fluid
                />
              )}
            />
          </>
        )}
        <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
          {t('list type')}
        </BodyRegularBold>
        <Controller
          name='listType'
          control={control}
          render={({ field }) => (
            <Dropdown
              data-testid='listType-dropdown'
              onSelect={(value) => handleFieldChange(field, value)}
              options={typeOptions}
              selected={listType}
              fluid
            />
          )}
        />
        {![ListTypeEnum.L2_QUALIFIER].includes(listType) && (
          <Grid item xs>
            <Grid container spacing={4}>
              <Grid item xs>
                <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                  {t('start date')}
                </BodyRegularBold>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      data-testid="startDate-dropdown"
                      selected={field.value}
                      onChange={(date) => handleFieldChange(field, date)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs>
                <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                  {t('end date')}
                </BodyRegularBold>
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      data-testid="endDate-dropdown"
                      selected={field.value}
                      onChange={(date) => handleFieldChange(field, date)}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {listType === ListTypeEnum.DIRECT_ACCEPTANCE && (
          <Grid item xs>
            <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
              {t('amount of players')}
            </BodyRegularBold>
            <Controller
              name="itemLimit"
              control={control}
              render={({ field }) => (
                <TextInput 
                  data-testid="itemLimit-dropdown"
                  placeholder={t('item limit')}
                  onChange={value => handleFieldChange(field, value)}
                  fullWidth
                  disableUnderline
                  outlined
                />
              )}
            />
          </Grid>
        )}
        {listType === ListTypeEnum.L2_QUALIFIER && (
          <Grid item xs>
            <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
              {t('run date')}
            </BodyRegularBold>
            <Controller
              name="runDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  data-testid="runDate-dropdown"
                  selected={field.value}
                  onChange={(date) => handleFieldChange(field, date)}
                />
              )}
            />
          </Grid>
        )}
        {listType === ListTypeEnum.QUOTA && (
          <Grid item xs>
            <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
              {t('section')}
            </BodyRegularBold>
            <Controller
              name='section'
              control={control}
              render={({ field }) => (
                <Dropdown
                  data-testid='section-dropdown'
                  onSelect={(value) => handleFieldChange(field, value)}
                  options={sectionOptions}
                  fluid
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs>
          <Grid container spacing={4}>
            {playerType !== PlayerTypeEnum.FAMILY &&
              <Grid item xs>
                <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                  {t('gender')} *
                </BodyRegularBold>
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      data-testid="gender-dropdown"
                      onSelect={(value) => handleFieldChange(field, value)}
                      options={genderOptions}
                      selected={gender}
                      fluid
                    />
                  )}
                />
              </Grid>
            }
            {(playerType === PlayerTypeEnum.WHEELCHAIR && divisionType !== DivisionTypesEnum.AGE) ? null : (
              <Grid item xs>
                <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                  {t('age group')}
                </BodyRegularBold>
                <Controller
                  name="ageRestriction"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      data-testid="ageRestriction-dropdown"
                      onSelect={(value) => handleFieldChange(field, value)}
                      options={ageGroupOptions}
                      selected={ageGroup}
                      fluid
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
          {hasSubDivisionField && (
            <Grid item xs>
              <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                {t('sub division')}
              </BodyRegularBold>
              <Controller
                name="subDivision"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    data-testid="subDivision-dropdown"
                    onSelect={(value) => handleFieldChange(field, value)}
                    options={subDivisionOptions}
                    fluid
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
        {hasFormatField && (
          <Grid item xs>
            <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
              {t('format')}
            </BodyRegularBold>
            <Controller
              name='matchFormat'
              control={control}
              render={({ field }) => (
                <Dropdown
                  data-testid='matchFormat-dropdown'
                  onSelect={(value) => handleFieldChange(field, value)}
                  options={formatOptions}
                  fluid
                />
              )}
            />
          </Grid>
        )}
        {divisionType === DivisionTypesEnum.NTRP && playerType === PlayerTypeEnum.ADULT && (
          <Grid item xs>
            <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
              {t('ntrp level')}
            </BodyRegularBold>
            <Controller
              name='playerLevel'
              control={control}
              render={({ field }) => (
                <Dropdown
                  data-testid='playerLevel-dropdown'
                  onSelect={(value) => handleFieldChange(field, value)}
                  options={applicableLevels}
                  fluid
                />
              )}
            />
          </Grid>
        )}
        <CustomGrid style={{ display: 'flex', justifyContent: 'flex-end' }} spacing={{ margins: { lg: 'top' } }}>
          <Button onClick={onCancel} type="button" level="tertiary">
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            spacing={{ margins: { sm: 'left' } }}
            loading={loading}
          >
            {t('create ranking list')}
          </Button>
        </CustomGrid>
        <CustomGrid style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {error && (
            <FormErrorMessage 
              spacing={{ margins: { md: 'top', sm: 'left' } }}
              message={t('generate ranklist error')}
            />
          )}
        </CustomGrid>
      </Grid>
    </form>
  )
}

export default NewRankingRunDialog
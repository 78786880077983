// extracted by mini-css-extract-plugin
export var bodyBase = "batch-ranking-process-card-module--body-base--NNhct batch-ranking-process-card-module--site-font--YbeqT";
export var bodyLarge = "batch-ranking-process-card-module--body-large--KOKZ6 batch-ranking-process-card-module--body-base--NNhct batch-ranking-process-card-module--site-font--YbeqT";
export var bodyLargeBold = "batch-ranking-process-card-module--body-large-bold--Wh09Z batch-ranking-process-card-module--body-base--NNhct batch-ranking-process-card-module--site-font--YbeqT";
export var bodyRegular = "batch-ranking-process-card-module--body-regular--cc46f batch-ranking-process-card-module--body-base--NNhct batch-ranking-process-card-module--site-font--YbeqT";
export var bodyRegularBold = "batch-ranking-process-card-module--body-regular-bold--nNnuB batch-ranking-process-card-module--body-base--NNhct batch-ranking-process-card-module--site-font--YbeqT";
export var bodySmall = "batch-ranking-process-card-module--body-small--upJ1+ batch-ranking-process-card-module--body-base--NNhct batch-ranking-process-card-module--site-font--YbeqT";
export var bodySmallBold = "batch-ranking-process-card-module--body-small-bold--8e5Rf batch-ranking-process-card-module--body-base--NNhct batch-ranking-process-card-module--site-font--YbeqT";
export var borderTop = "batch-ranking-process-card-module--border-top--io6D+";
export var breakWordContainer = "batch-ranking-process-card-module--break-word-container--mns-N";
export var buttonIcon = "batch-ranking-process-card-module--button-icon--7Umks";
export var buttonIconBase = "batch-ranking-process-card-module--button-icon-base--WSKc6";
export var clickLink = "batch-ranking-process-card-module--click-link--duQkL";
export var dropdownBase = "batch-ranking-process-card-module--dropdown-base--VuyB6";
export var dropdownSelectBase = "batch-ranking-process-card-module--dropdown-select-base--+6NJ8 batch-ranking-process-card-module--text-input--A2rUv";
export var flexCol = "batch-ranking-process-card-module--flex-col--f3Bej";
export var formErrorMessage = "batch-ranking-process-card-module--form-error-message--I8ycG";
export var h3 = "batch-ranking-process-card-module--h3--1xw5s";
export var h4 = "batch-ranking-process-card-module--h4--ifUVR";
export var hoverLink = "batch-ranking-process-card-module--hover-link--Onacv";
export var hoverRow = "batch-ranking-process-card-module--hover-row--HZctq";
export var membershipContainer = "batch-ranking-process-card-module--membership-container--VSWlZ batch-ranking-process-card-module--flex-col--f3Bej batch-ranking-process-card-module--primary-border--Y1Cn0";
export var membershipHeader = "batch-ranking-process-card-module--membership-header--8mv8w";
export var membershipHeading = "batch-ranking-process-card-module--membership-heading--WJCwn";
export var membershipLabel = "batch-ranking-process-card-module--membership-label--yU1ih";
export var moreFiltersBorderClass = "batch-ranking-process-card-module--more-filters-border-class--Fsd++";
export var pageBg = "batch-ranking-process-card-module--page-bg--MDYoH";
export var pointer = "batch-ranking-process-card-module--pointer--S1JQK";
export var primaryBorder = "batch-ranking-process-card-module--primary-border--Y1Cn0";
export var selectActionButtonText = "batch-ranking-process-card-module--select-action-button-text--fBIat";
export var selectedActionButton = "batch-ranking-process-card-module--selected-action-button--hIBa9 batch-ranking-process-card-module--unstyled-button--SnaIW";
export var selectedContainer = "batch-ranking-process-card-module--selected-container--lp3HJ";
export var selectedText = "batch-ranking-process-card-module--selected-text--Ox97j";
export var shadowBoxLight = "batch-ranking-process-card-module--shadow-box-light--swaTy";
export var siteFont = "batch-ranking-process-card-module--site-font--YbeqT";
export var slideDownAndFade = "batch-ranking-process-card-module--slideDownAndFade--R8iNi";
export var slideLeftAndFade = "batch-ranking-process-card-module--slideLeftAndFade--bDpNa";
export var slideRightAndFade = "batch-ranking-process-card-module--slideRightAndFade--Mkcrw";
export var slideUpAndFade = "batch-ranking-process-card-module--slideUpAndFade--x2W2D";
export var statusDecoration = "batch-ranking-process-card-module--status-decoration--Tz+6a";
export var textInput = "batch-ranking-process-card-module--text-input--A2rUv";
export var textInverted = "batch-ranking-process-card-module--text-inverted--jPP0Z";
export var textMediumDark = "batch-ranking-process-card-module--text-medium-dark--bswCQ";
export var tooltipFont = "batch-ranking-process-card-module--tooltipFont--hnp3s";
export var unstyledButton = "batch-ranking-process-card-module--unstyled-button--SnaIW";
import React from 'react'
import * as styles from './batch-ranking-process-card.module.less'
import { CustomGrid } from '../../custom-grid/custom-grid'
import { useTranslation } from 'react-i18next'
import Icon from '../../icon/icon'

interface BatchRankingProcessCardProps {
  selectedCount: number
  openBatchRunDialog: (open: boolean) => void
  openPublishRunDialog: (open: boolean) => void
}

const BatchRankingProcessCard: React.FC<BatchRankingProcessCardProps> = ({
  selectedCount,
  openBatchRunDialog,
  openPublishRunDialog
}) => {
  const { t } = useTranslation()

  return (
    <CustomGrid
      container
      className={styles.selectedContainer}
      alignItems="center"
    >
      <span className={styles.selectedText}>
        {selectedCount} {t('selected')}
      </span>
      <button className={styles.selectedActionButton} onClick={() => openBatchRunDialog(true)}>
        <Icon
          name="md-add-circle-full"
          spacing={{ margins: { xxs: 'right' } }}
          className={styles.buttonIcon}
        />
        <span className={styles.selectActionButtonText}>{t('new run')}</span>
      </button>
      <button className={styles.selectedActionButton} onClick={() => openPublishRunDialog(true)}>
        <Icon
          name="md-launch"
          spacing={{ margins: { xxs: 'right' } }}
          className={styles.buttonIcon}
        />
        <span className={styles.selectActionButtonText}>{t('publish latest runs')}</span>
      </button>
    </CustomGrid>
  )
}

export default BatchRankingProcessCard
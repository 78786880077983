import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'src/components/button/button'
import { CustomGrid } from 'src/components/custom-grid/custom-grid'
import { Body } from 'src/components/typography/typography'

interface PublishLatestRunDialogProps {
  onCancel: () => void
  onSubmit: () => void
  loading: boolean
  numberOfSelected: number
}

const PublishLatestRunDialog: React.FC<PublishLatestRunDialogProps> = ({ 
  onCancel, 
  onSubmit, 
  loading, 
  numberOfSelected 
}) => {
  const { t } = useTranslation()

  const handleOnSubmit = useCallback(() => {
    onSubmit()
  },[onSubmit])

  const handleOnCancel = useCallback(() => {
    onCancel()
  },[onCancel])

  return (
    <CustomGrid container direction='row'>
      <Body size="lg">{t('publish multiple runs', { amount: numberOfSelected })}</Body>
      <CustomGrid container justifyContent="flex-end" spacing={{ margins: { md: 'top' } }}>
        <Button level="tertiary" onClick={handleOnCancel}>
          {t('cancel')}
        </Button>
        <Button
          onClick={handleOnSubmit}
          spacing={{ margins: { sm: 'left' } }}
          loading={loading}
        >
          {t('confirm and publish')}
        </Button>
      </CustomGrid>
    </CustomGrid>
  )
}

export default PublishLatestRunDialog
import React, { useCallback, useState } from 'react'
import Panel from '../../panel/panel'
import { useTranslation } from 'react-i18next'
import RankingRunGroupsFilter from './ranking-run-group-filters'
import EmptyState from '../../empty-state/empty-state'
import AdminTable, { DataCols } from '../../admin-table/admin-table'
import useColumns from '../utils/useRankListRunGroupColumns'
import { getCheckedAllFields } from '../../usta-rankings/helpers'
import BatchRankingProcessCard from './batch-ranking-process-card'
import { RunGroupsWithSchedules } from '../rankings-home'
import { ControlProps } from 'src/components/table-controls/table-controls'

export interface RunGroupFilter {
  category: string
  division: string
  format: string
  listType: string
  section: string
  publishStatus: string
  gender: string
}

interface RankingRunGroupsPanelProps {
  setBatchRunDialogOpen: (open: boolean) => void
  setPublishRunDialogOpen: (open: boolean) => void
  setSelectedRankListGroups: (groups: any[]) => void
  runGroupsWithSchedules: Partial<RunGroupsWithSchedules>[]
  handleNavigateToRankingRun: (row: any) => void
  filter: RunGroupFilter
  setFilter: (filter: RunGroupFilter) => void
  controlProps: ControlProps
  loading: boolean
}

const RankingRunGroupsPanel: React.FC<RankingRunGroupsPanelProps> = ({
  setBatchRunDialogOpen,
  setPublishRunDialogOpen,
  setSelectedRankListGroups,
  runGroupsWithSchedules,
  handleNavigateToRankingRun,
  filter,
  setFilter,
  controlProps,
  loading,
}) => {
  const { t } = useTranslation()
  const [checked, setChecked] = useState<{ [key: string]: boolean }>({})
  const isChecked = (id: string): boolean => Boolean(checked?.[id])
  const [allChecked, setAllChecked] = useState(false)

  const handleChecked = useCallback((checked) => {
    setChecked(checked)
    const selectedIds: string[] = Object.entries(checked)
      .filter(([_, value]) => value)
      .map(([key, _]) => key)
    setSelectedRankListGroups(runGroupsWithSchedules?.filter(g => selectedIds.includes(g.id as string)))
  }, [setChecked, setSelectedRankListGroups, runGroupsWithSchedules])

  const handleAllGroupsChecked = useCallback((checked) => {
    setAllChecked(checked)
    handleChecked(getCheckedAllFields(runGroupsWithSchedules, checked))
  }, [setAllChecked, handleChecked, runGroupsWithSchedules])

  const numberOfSelectedRows = Object.values(checked).filter(Boolean).length

  const cols: DataCols<any> = useColumns({
    allChecked,
    loading,
    checked,
    setAllChecked: handleAllGroupsChecked,
    setChecked: handleChecked,
    isChecked,
  })

  return (
    <Panel extendedPadding>
      <RankingRunGroupsFilter filters={filter} setFilter={setFilter} />
      {!loading && runGroupsWithSchedules?.length === 0 ? (
        <EmptyState title={t('no rankings yet')} icon="xl-rankings" />
      ) : (
        <AdminTable
          data={runGroupsWithSchedules}
          columns={cols}
          controls={controlProps}
          onRowClick={(row: any) => handleNavigateToRankingRun(row)}
          hideTopPaginationInfo={
            numberOfSelectedRows ? (
              <BatchRankingProcessCard
                selectedCount={numberOfSelectedRows}
                openBatchRunDialog={setBatchRunDialogOpen}
                openPublishRunDialog={setPublishRunDialogOpen}
              />
            ) : (
              undefined
            )
          }
          columnSelectorId={'ranking-run-groups'}
          filters={filter}
        />
      )}
    </Panel>
  )
}

export default RankingRunGroupsPanel

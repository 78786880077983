import React, { useMemo } from 'react'
import { Checkbox } from '../../formik-fields/formik-fields'
import { Tooltip, Grid } from '@material-ui/core'
import { Body } from '../../typography/typography'
import { useOrgLevel } from 'src/utils/auth'
import { useTranslation } from 'react-i18next'
import { parseCronValue } from 'src/utils/parse-cron-value/parse-cron-value'
import Icon from '../../icon/icon'
import { generateRanklistName } from 'src/utils/generate-ranklist-name/generate-ranklist-name'
import {
  PlayerTypeEnum,
  RankListGenderEnum
} from 'src/graphql-types/globalRankingTypes'
import { retrieveColumnPreference } from 'src/utils/storage/local-storage'
import moment from 'moment'
import StatusLabel, { LabelVariety } from '../../status-label/status-label'
import { capitalize } from 'src/utils/helper/rankings'
import { DataCols } from '../../admin-table/admin-table'
import { RanklistRunGroups_ranklistRunGroups_items as RanklistRunGroupsItem } from 'src/graphql-types/RanklistRunGroups'
import { getAgeRestrictionLabel, getDivisionTypeLabel, getFormat, getGenderFromEnum, getListType, getPlayerTypeLabel } from 'src/components/usta-rankings/helpers'

const tableId = 'usta_rankings'

interface ColumnsProps {
  allChecked: boolean
  setAllChecked: (c: boolean) => void
  loading: boolean
  isChecked: (id: string) => boolean
  setChecked: (s: any) => void
  checked: { [key: string]: boolean }
}

const useColumns = ({
  allChecked,
  setAllChecked,
  loading,
  isChecked,
  setChecked,
  checked
}: ColumnsProps) => {
  const { t } = useTranslation()
  const { isNational } = useOrgLevel()
  const storedColumnPreference = useMemo(() => retrieveColumnPreference(tableId), [])

  const columns: DataCols<RanklistRunGroupsItem & { id: string }> = [
    {
      key: 'checkbox',
      title: (
        <Checkbox
          checked={allChecked}
          onChecked={checked => setAllChecked(checked)}
          disabled={loading}
        />
      ),
      getValue: m => (
        <Checkbox
          checked={isChecked(m.id)}
          onChecked={isChecked => setChecked({ ...checked, [m.id]: isChecked })}
          disabled={loading}
        />
      ),
      hidden: !isNational,
      sort: false
    },
    {
      key: 'scheduled',
      title: ' ',
      getValue: m => {
        const schedule = m.schedule
        if (!schedule || schedule.disabled) return null

        return (
          <Tooltip
            placement="top"
            title={
              <Grid container direction="column">
                <Body size="sm" color={Body.color.WHITE} bold>
                  {t('scheduled runs')}:
                </Body>
                <Body size="xs" color={Body.color.WHITE} spacing={{ margins: { xxs: 'top' } }}>
                  {parseCronValue({
                    value: schedule?.data?.frequency,
                    t,
                    nextRunAt: schedule?.nextRunAt
                  })}
                </Body>
              </Grid>
            }
          >
            <div>
              <Icon name="sm-clock" />
            </div>
          </Tooltip>
        )
      },
      noPadding: true,
      sort: false
    },
    {
      key: 'ranklist',
      title: t('ranklist'),
      getValue: m => (
        generateRanklistName(m, t)
      ),
      sort: false,
      columnToggle: { checked: true, disabled: true }
    },
    {
      key: 'playerType',
      title: t('player category'),
      getValue: m => capitalize(getPlayerTypeLabel(m.playerType, t)),
      columnToggle: {
        checked: storedColumnPreference?.['playerType'] ?? true
      }
    },
    {
      key: 'ageRestriction',
      title: t('age group'),
      getValue: m =>
        getAgeRestrictionLabel({
          playerType: m.playerType,
          ageRestriction: m.ageRestriction,
          divisionType: m.divisionType,
          playerLevel: m.playerLevel,
          t
        } as any),
      columnToggle: {
        checked: storedColumnPreference?.['ageRestriction'] ?? true
      }
    },
    {
      key: 'gender',
      title: t('gender'),
      getValue: m => {
        if (m.playerType === PlayerTypeEnum.FAMILY) {
          return getGenderFromEnum(m.gender, m.playerType, t, true)
        }
        const gender = m.gender === RankListGenderEnum.X ? m.genderModifier : m.gender
        if (!gender) return t('n/a')
        return getGenderFromEnum(gender as RankListGenderEnum, m.playerType, t, true)
      },
      columnToggle: {
        checked: storedColumnPreference?.['gender'] ?? false,
        index: 9
      }
    },
    {
      key: 'divisionType',
      title: t('division type'),
      getValue: m =>
        getDivisionTypeLabel({
          playerType: m.playerType,
          familyCategory: m.familyCategory,
          divisionType: m.divisionType,
          playerLevel: m.playerLevel,
          t
        }),
      sort: false,
      columnToggle: {
        checked: storedColumnPreference?.['divisionType'] ?? true
      }
    },
    {
      key: 'matchFormat',
      title: t('format'),
      getValue: m => getFormat(m, t),
      columnToggle: {
        checked: storedColumnPreference?.['matchFormat'] ?? true
      }
    },
    {
      key: 'listType',
      title: t('list type'),
      getValue: m => getListType(m.listType, t),
      columnToggle: {
        checked: storedColumnPreference?.['listType'] ?? true
      }
    },
    {
      key: 'latestListCreatedAt',
      title: t('last run'),
      getValue: m => {
        if (!m?.latestListCreatedAt) return '-'
        return t('payment date', {
          date: moment(m?.latestListCreatedAt).local()
        })
      },
      columnToggle: {
        checked:
          storedColumnPreference?.['latestListCreatedAt'] ?? true
      }
    },
    {
      key: 'latestListVisible',
      title: t('latest run status'),
      getValue: m => {
        const status = m.latestListVisible
          ? { text: t('published'), color: 'success' }
          : { text: t('hidden'), color: 'warning' }

        return <StatusLabel variety={status.color as LabelVariety}>{status.text}</StatusLabel>
      },
      columnToggle: {
        checked:
          storedColumnPreference?.['latestListVisible'] ?? true
      }
    }
  ]

  return columns
}

export default useColumns
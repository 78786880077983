import { StaffScope, useHasScope } from 'src/utils/auth'
import { NTRPLevelOptions } from './getNTRPLevelOptions'
import { useTranslation } from 'react-i18next'
import useAgeGroupOptions from './useAgeGroupOptions'
import { AgeRestrictionEnum, ListTypeEnum, PlayerLevelEnum, PlayerTypeEnum, RankListGenderEnum } from 'src/graphql-types/globalRankingTypes'
import { DivisionTypesEnum, getFamilyDivisionTypeOptions, getRegionOptions, useWheelchairDivisionTypeOptions } from 'src/utils/helper/rankings'

const useRankListOptions = ({ circuit, divisionType }) => {
  const { t } = useTranslation()
  const { 
    getAdultAgeGroups, 
    getFamilyAgeGroupOptions, 
    getAdultFormatOptions,
    getJuniorListTypeOptions,
    getJuniorFormatOptions,
    getWheelchairGenderOptions,
  } = useAgeGroupOptions()
  const rankingsAccess = useHasScope([StaffScope.RANKINGS])
  const familyDivisionTypeOptions = getFamilyDivisionTypeOptions(t)
  const wheelchairDivisionTypeOptions = useWheelchairDivisionTypeOptions()

  if (circuit === PlayerTypeEnum.ADULT) {
    const ageGroupOptions = getAdultAgeGroups(divisionType, t)
    const formatOptions = getAdultFormatOptions(divisionType, t)

    return {
      divisionTypeOptions: [
        { label: t('age'), value: DivisionTypesEnum.AGE },
        { label: t('ntrp'), value: DivisionTypesEnum.NTRP }
      ],
      typeOptions: [
        { label: t('standing'), value: ListTypeEnum.STANDING },
        { label: t('year end'), value: ListTypeEnum.YEAR_END }
      ],
      genderOptions: [
        { label: t('men'), value: RankListGenderEnum.M },
        { label: t('women'), value: RankListGenderEnum.F }
      ],
      ageGroupOptions,
      formatOptions,
      NTRPLevelOptions: divisionType === DivisionTypesEnum.NTRP ? NTRPLevelOptions : [],
    }
  } else if (circuit === PlayerTypeEnum.JUNIOR) {
    return {
      typeOptions: getJuniorListTypeOptions({ rankingsAccess, t, emptyOption: false }),
      formatOptions: getJuniorFormatOptions(t),
      genderOptions: [
        { label: t('gender option boys'), value: RankListGenderEnum.M },
        { label: t('gender option girls'), value: RankListGenderEnum.F }
      ],
      ageGroupOptions: [
        { label: t('and under', { category: '12' }), value: AgeRestrictionEnum.y12 },
        { label: t('and under', { category: '14' }), value: AgeRestrictionEnum.y14 },
        { label: t('and under', { category: '16' }), value: AgeRestrictionEnum.y16 },
        { label: t('and under', { category: '18' }), value: AgeRestrictionEnum.y18 }
      ],
      sectionOptions: getRegionOptions({ emptyOption: false, t }),
      divisionTypeOptions: [],
    }
  } else if (circuit === PlayerTypeEnum.FAMILY) {
    return {
      divisionTypeOptions: familyDivisionTypeOptions,
      typeOptions: [
        { label: t('standing'), value: ListTypeEnum.STANDING },
        { label: t('year end'), value: ListTypeEnum.YEAR_END }
      ],
      formatOptions: [{ label: t('team doubles'), value: 'TEAM DOUBLES' }],
      genderOptions: [
        { label: t('men'), value: RankListGenderEnum.M },
        { label: t('women'), value: RankListGenderEnum.F },
        { label: t('mixed'), value: RankListGenderEnum.X }
      ],
      ageGroupOptions: getFamilyAgeGroupOptions(t)
    }
  } else if (circuit === PlayerTypeEnum.WHEELCHAIR) {
    return {
      divisionTypeOptions: wheelchairDivisionTypeOptions,
      typeOptions: [
        { label: t('standing'), value: ListTypeEnum.STANDING },
        { label: t('year end'), value: ListTypeEnum.YEAR_END }
      ],
      formatOptions: [
        { label: t('singles'), value: 'SINGLES' },
        { label: t('individual doubles'), value: 'INDIVIDUAL DOUBLES' }
      ],
      genderOptions: getWheelchairGenderOptions(divisionType, t),
      ageGroupOptions: [
        { label: t('open'), value: 'Op' },
        { label: t('and under', { category: '18' }), value: AgeRestrictionEnum.y18 }
      ],
      subDivisionOptions: [
        { value: PlayerLevelEnum.A, label: t('coed a') },
        { value: PlayerLevelEnum.B, label: t('coed b') },
      ]
    }
  }
}

export default useRankListOptions
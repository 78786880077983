import React, { useState, SetStateAction, Dispatch } from 'react'
import { Formik, Form } from 'formik'
import { Grid } from '@material-ui/core'
import { Body } from '../typography/typography'
import { RangeContainer, DateField } from '../formik-fields/formik-fields'
import { CustomGrid } from '../custom-grid/custom-grid'
import Button from '../button/button'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import { GENERATE_RANK_LISTS, GET_RANKLIST_RUN_GROUPS } from '../usta-rankings/usta-ranking-queries'
import { meshGatewayClient } from 'src/apollo/client'
import FormErrorMessage from '../form-error-message/form-error-message'
import * as styles from './batch-run-dialog.module.less'
import Icon from '../icon/icon'
import { useQueryParams, BooleanParam, ArrayParam } from 'use-query-params'
import {
  RanklistRunGroups,
  RanklistRunGroups_ranklistRunGroups_items as RanklistGroupItem
} from 'src/graphql-types/RanklistRunGroups'
import { RankListDateRangeInput } from 'src/graphql-types/globalRankingTypes'
import { GenerateRankLists } from 'src/graphql-types/GenerateRankLists'
import { getFamilyAgeRestriction } from '../usta-ranklist/usta-ranklist.utils'

interface InitialValues {
  startDate: Date
  endDate: Date
}

const initialValues: InitialValues = {
  startDate: new Date(),
  endDate: new Date()
}

interface BatchRunDialogProps {
  setDialog: Dispatch<SetStateAction<boolean>>
  selected: any[]
  checkRankListStatus: any
  polling: any
  setPolling: any
  ranklistJobs: any
  totalItems: number
  filters?: any
}

export const BatchRunDialog: React.FC<BatchRunDialogProps> = ({
  setDialog,
  selected,
  checkRankListStatus,
  polling,
  setPolling,
  ranklistJobs,
  totalItems,
  filters
}) => {
  const [allSelected, setAllSelected] = useState(false)
  const numberOfSelected = selected.length
  const { t } = useTranslation()
  const [query, setQuery] = useQueryParams({
    ranklistJobs: ArrayParam,
    selectedAllItems: BooleanParam
  })

  const [dates, setDates] = useState<Record<'startDate' | 'endDate', Date>>({
    startDate: new Date(),
    endDate: new Date()
  })

  const [generateRankLists, { loading: generatingRankLists, error }] = useMutation<
    GenerateRankLists
  >(GENERATE_RANK_LISTS, {
    client: meshGatewayClient
  })

  const { data: ranklistRunGroupsData, refetch } = useQuery<RanklistRunGroups>(
    GET_RANKLIST_RUN_GROUPS,
    {
      variables: { pageArgs: { limit: totalItems, skip: 0 }, ranklistRunGroupFilter: filters },
      client: meshGatewayClient,
      skip: allSelected
    }
  )

  const handleSubmit = async (values: InitialValues) => {
    try {
      const dateRange = {
        start: values.startDate,
        end: values.endDate
      }
      let ids: any = []

      if (query.selectedAllItems) {
        setAllSelected(true)
        await refetch({
          variables: { pageArgs: { limit: totalItems, skip: 0 }, ranklistRunGroupFilter: filters }
        })

        const generateRanklistsInput = ranklistRunGroupsData?.ranklistRunGroups.items.map(item =>
          getRanklistVarsFromRanklistGroup(item, dateRange)
        )

        const response = await generateRankLists({
          variables: {
            input: generateRanklistsInput
          }
        })

        ids = response.data?.generateRankLists?.map(g => g?.id)
      } else {
        const generateRanklistsInput = selected.map(item =>
          getRanklistVarsFromRanklistGroup(item, dateRange)
        )

        const response = await generateRankLists({
          variables: {
            input: generateRanklistsInput
          }
        })

        ids = response.data?.generateRankLists?.map(g => g?.id)
      }
      setQuery({ ranklistJobs: ids })
      checkRankListStatus({ variables: { ids } })
      setPolling(true)
      setDialog(false)
    } catch (error) {}
  }

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values }) => (
          <Form>
            <CustomGrid container direction="column" spacing={{ margins: { xs: 'bottom' } }}>
              <Grid container>
                <CustomGrid item xs spacing={{ margins: { sm: 'right' } }}>
                  <Body size="md" spacing={{ margins: { xs: 'vertical' } }}>
                    {t('run period start')}
                  </Body>
                  <RangeContainer>
                    <DateField
                      name="startDate"
                      onChange={date => setDates({ ...dates, startDate: date })}
                      datePickerProps={{
                        disabled: false,
                        selected: new Date(values.startDate),
                        popperPlacement: 'bottom',
                        maxDate: new Date()
                      }}
                    />
                  </RangeContainer>
                </CustomGrid>
                <CustomGrid item xs spacing={{ margins: { sm: 'left' } }}>
                  <Body size="md" spacing={{ margins: { xs: 'vertical' } }}>
                    {t('run period end')}
                  </Body>
                  <RangeContainer>
                    <DateField
                      name="endDate"
                      onChange={date => setDates({ ...dates, endDate: date })}
                      datePickerProps={{
                        disabled: false,
                        selected: new Date(values.endDate),
                        popperPlacement: 'bottom',
                        minDate: new Date(values.startDate)
                      }}
                    />
                  </RangeContainer>
                </CustomGrid>
              </Grid>
            </CustomGrid>
            <Body size="lg">
              {t('batch run explanation', {
                amount: query.selectedAllItems ? totalItems : numberOfSelected
              })}
            </Body>
            <Annotation />
            <CustomGrid container justify="flex-end" spacing={{ margins: { lg: 'top' } }}>
              <Button onClick={() => setDialog(false)} type="button" level="tertiary">
                {t('cancel')}
              </Button>
              <Button
                type="submit"
                spacing={{ margins: { sm: 'left' } }}
                loading={polling || generatingRankLists}
              >
                {t('create new runs')}
              </Button>
            </CustomGrid>
          </Form>
        )}
      </Formik>
      <CustomGrid container justify="flex-end">
        {error && (
          <FormErrorMessage
            spacing={{ margins: { md: 'top' } }}
            message={t('generate ranklist error')}
          />
        )}
        {!error && ranklistJobs?.rankListJob?.jobErrors && (
          <FormErrorMessage
            spacing={{ margins: { md: 'top' } }}
            message={ranklistJobs?.rankListJob?.jobErrors?.[0]}
          />
        )}
      </CustomGrid>
    </>
  )
}

const Annotation = () => {
  const { t } = useTranslation()

  return (
    <CustomGrid
      container
      className={styles.annotationContainer}
      spacing={{ margins: { lg: 'top' } }}
    >
      <Grid item xs={1}>
        <Icon name="md-clock" />
      </Grid>
      <Grid item xs={10}>
        <Body size="lg" bold>
          {t('creating multiple runs')}
        </Body>
        <Body size="lg" spacing={{ margins: { xs: 'top' } }}>
          {t('cannot generate runs')}
        </Body>
      </Grid>
    </CustomGrid>
  )
}

const getRanklistVarsFromRanklistGroup = (
  ranklistGroup: RanklistGroupItem,
  dateRange?: RankListDateRangeInput
) => {
  const {
    listType,
    playerType,
    ageRestriction,
    gender,
    genderModifier,
    matchFormat,
    matchFormatType,
    familyCategory,
    playerLevel,
    region
  } = ranklistGroup

  return {
    listType,
    playerType,
    ...(familyCategory ? { familyCategory } : {}),
    ...(ageRestriction ? { ageRestriction } : {}),
    ...(gender ? { gender } : {}),
    ...(genderModifier ? { genderModifier } : {}),
    ...(matchFormat ? { matchFormat } : {}),
    ...(matchFormatType ? { matchFormatType } : {}),
    ...(playerLevel ? { playerLevel } : {}),
    ...(region ? { region } : {}),
    ...getFamilyAgeRestriction(playerType, ageRestriction),
    dateRange
  }
}

import graphql from 'graphql-tag'

const TD_GET_RANK_LIST_JOBS = graphql`
  query TD_RankListJob(
    $filter: td_RankListJobFilter!
  ) {
    td_rankListJobs(filter: $filter) {
      id,
      jobComplete
      jobErrors
      rankListId
      createdAt
      updatedAt
    }
  }
`

export default TD_GET_RANK_LIST_JOBS